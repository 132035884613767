<template>
  <div class="tool-box">
    <el-form :inline="true" :model="search">
      <el-form-item>
        <el-input v-model.trim="search.channelName" clearable class="name">
          <template slot="prepend">渠道名称</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          class="time"
          v-model="date"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchList">搜索</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      search: {
        channelName: ''
      },
      date: []
    };
  },
  methods: {
    searchList() {
      let [beginTime = '', stopTime = ''] = this.date || [];
      let param = Object.assign({ beginTime, stopTime }, this.search);
      this.$emit('search', param);
    }
  }
};
</script>
<style lang="less">
@media screen and (max-width: 480px) {
  .el-range-editor.el-input__inner{
    width: 100%!important;
  }


  .el-date-range-picker{
    width: 100%!important;
    left: 0!important;
  }
  .el-date-range-picker .el-picker-panel__body{
    width: 100%;
    min-width: 100%!important;
  }
  .el-date-range-picker__content .el-date-range-picker__header div{
    text-align: center;
      width: 100%;
      margin-left: 0!important;
      margin-right: 0!important;
  }
}
</style>
