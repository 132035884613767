import API from "../../../api/index";

export default {
  getStatisticsList: params => {
    return API.requestGet({
      url: "link/statistics",
      params,
      auth: true
    });
  }
};
